import React, { Component } from 'react';
import { connect } from 'react-redux';
import trashIcon from '../../images/pharmacy/trash.svg';
import editIcon from '../../images/pharmacy/edit.svg';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import checkedIcon from '../../images/pharmacy/checked.svg';
import plusIcon from '../../images/pharmacy/plus.svg';
import homeIcon from '../../images/pharmacy/home.svg';
import crossIcon from '../../images/common/cross.svg';
import { editPatient, saveNewPatient } from '../../services';
import { logEvent } from '../../utils/logEvent';
import profileIcon from '../../images/lab-test/profile.svg';
import styled from 'styled-components';
import { FaPlus, FaTimes } from 'react-icons/fa';
import StyledTappable from '../../components/common/StyledTappable';
import pencilIcon from '../../images/lab-test/pencil.svg';
import moment from 'moment';

const AddTextIcon = styled(FaPlus)`
  color: #714fff;
  height: 10px;
  width: 10px;
`;

const CloseContainer = styled(StyledTappable)`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(FaTimes)`
  color: #413958;
`;

export class PatientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPatients: true,
      showNewPatient: false,
      updateAddress: {},
      userName: '',
      userAge: '',
      userContact: '',
      userGender: '',
      editUser: '',
    };
  }

  componentDidMount() {}

  hideModal = () => {
    if (this.state.showNewPatient) {
      return this.setState({
        showPatients: true,
        showNewPatient: false,
        userName: '',
        userAge: '',
        userContact: '',
        userGender: '',
        editUser: null,
      });
    }
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (
      (this.node && this.node.contains(e.target)) ||
      (this.node2 && this.node2.contains(e.target))
    ) {
      return;
    }
    if (this.state.showNewPatient) {
      return this.setState({
        showPatients: true,
        showNewPatient: false,
        userName: '',
        userAge: '',
        userContact: '',
        userGender: '',
        editUser: null,
      });
    }
    this.hideModal();
  };

  addNewPatientModal = (user = null) => {
    this.hideModal();
    this.setState({
      showNewPatient: true,
      editUser: user,
    });
    if (user) {
      this.setState({
        userName: user.name,
        userAge: user.age,
        userContact: user.phone,
        userGender: user.gender,
      });
    }
  };

  saveNewPatient = () => {
    this.setState({
      saving: true,
    });
    const patientDetails = {
      name: this.state.userName,
      age: this.state.userAge,
      phone: this.state.userContact,
      gender: this.state.userGender,
    };
    const { authToken } = this.props;

    if (this.state.editUser) {
      patientDetails.patientId = this.state.editUser.patientId;

      editPatient(patientDetails)
        .then((res) => {
          console.log(res);
          this.setState({
            editUser: null,
          });
          this.props.fetchPatients();
          this.hideModal();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    } else {
      saveNewPatient(patientDetails)
        .then((res) => {
          console.log(res);
          this.setState({
            editUser: null,
          });
          this.getPatients();
          this.hideModal();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  };

  render() {
    const { userContact, userAge, userName, showPatients, showNewPatient } =
      this.state;
    const { patients } = this.props;
    const renderPatient = (ele) => {
      if (ele.dob) {
        const dob = moment(ele.dob).format('yyyy-MM-DD');
        ele.age = moment().diff(dob, 'years');
      }
      return (
        <div
          className="flex flex-row justify-between items-center rounded-12 bg-txtsecondary-shades4 bg-opacity-50 mb-20px py-24px pl-16px pr-20px"
          key={ele.patientId}
        >
          <div className="flex flex-row items-center">
            <div
              className="bg-txtsecondary-shades7 rounded-full"
              style={{ width: '22px', height: '22px' }}
              onClick={() => this.props.selectPatient(ele)}
            ></div>
            <div
              className="txt-black-600-12 pl-12px flex flex-col"
              onClick={() => {
                this.props.selectPatient(ele);
              }}
            >
              <span>{ele.name}</span>
              <span>
                {ele.age},{ele.gender}
              </span>
              <span className="txt-grey-10">{ele.phone}</span>
            </div>
          </div>
          {!this.props.noEdit && (
            <div className="flex flex-row items-center">
              <img
                className="hover"
                src={pencilIcon}
                height="20px"
                width="20px"
                alt="edit"
                onClick={() => {
                  this.addNewPatientModal(ele);
                }}
              />
            </div>
          )}
        </div>
      );
    };
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={showPatients}
            mountOnEnter
            unmountOnExit
            onExited={this.hideModal}
            appear
          >
            <div className="modalBody" ref={(node) => (this.node = node)}>
              <div className="modalHeader">
                <div className="flex flex-row items-center">
                  <img src={profileIcon} alt="" />
                  <p className="ml-10px text-body-l text-txtlight-new font-semibold">
                    Select patient to continue
                  </p>
                </div>
                <CloseContainer onTap={this.hideModal}>
                  <CloseIcon />
                </CloseContainer>
              </div>
              <div className="modalDetails">{patients.map(renderPatient)}</div>
            </div>
          </FlyInTransition>
          <FlyInTransition
            in={showNewPatient}
            mountOnEnter
            unmountOnExit
            onExited={this.hideModal}
            appear
          >
            <div className="modalBody" ref={(node) => (this.node = node)}>
              <div className="modalHeader">
                <div className="flex flex-row items-center">
                  <img src={profileIcon} />
                  <p className="ml-10px text-body-l text-txtlight-new font-semibold">
                    Add Patient
                  </p>
                </div>
                <CloseContainer onTap={this.hideModal}>
                  <CloseIcon />
                </CloseContainer>
              </div>
              <div className="modalDetails" style={{}}>
                <div>
                  <p className="text-body-s text-txtlight font-medium">
                    Full Name
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Full Name"
                    value={userName}
                    onChange={(e) => {
                      this.setState({
                        userName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">Age</p>
                  <input
                    type="number"
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Age*"
                    value={userAge}
                    onChange={(e) => {
                      this.setState({
                        userAge: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Gender
                  </p>
                  <div className="flex flex-row w-full justify-start mt-8px">
                    <span
                      className="text-center relative pt-8px pb-8px border border-txtsecondary-shades7 border-solid rounded-12 pr-24px pl-24px mr-24px"
                      onClick={() => {
                        this.setState({
                          userGender: 'Male',
                        });
                      }}
                      style={
                        this.state.userGender === 'Male'
                          ? { borderColor: '#6C5DD3' }
                          : {}
                      }
                    >
                      <p
                        className="text-body-l font-regular"
                        style={
                          this.state.userGender === 'Male'
                            ? { color: '#6C5DD3', fontWeight: '600' }
                            : { color: '#302E6B' }
                        }
                      >
                        Male
                      </p>
                      {this.state.userGender === 'Male' && (
                        <span className="absolute -top-10px -right-10px">
                          <img src={checkedIcon} alt="tick" />
                        </span>
                      )}
                    </span>
                    <span
                      className="text-center relative pt-8px pb-8px border border-txtsecondary-shades7 border-solid rounded-12 pr-24px pl-24px"
                      onClick={() => {
                        this.setState({
                          userGender: 'Female',
                        });
                      }}
                      style={
                        this.state.userGender === 'Female'
                          ? { borderColor: '#6C5DD3' }
                          : {}
                      }
                    >
                      <p
                        className="text-body-l font-regular"
                        style={
                          this.state.userGender === 'Female'
                            ? { color: '#6C5DD3', fontWeight: '600' }
                            : { color: '#302E6B' }
                        }
                      >
                        Female
                      </p>
                      {this.state.userGender === 'Female' && (
                        <span className="absolute -top-10px -right-10px">
                          <img src={checkedIcon} alt="tick" />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Contact Number
                  </p>
                  <input
                    type="number"
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="contact*"
                    value={userContact}
                    onChange={(e) => {
                      this.setState({
                        userContact: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <div
                    className="pl-16px pr-16px mt-12px rounded-12 hover pt-12px pb-12px text-button-s font-semibold text-textbox text-center flex flex-row justify-center items-center"
                    style={{
                      background: '#6C5DD3',
                      backdropFilter: 'blur(4px)',
                      width: '100%',
                    }}
                    onClick={this.saveNewPatient}
                  >
                    <span className="flex flex-row items-center">
                      <p className="mr-8px text-body-l font-semibold text-textbox">
                        Next
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(PatientModal);
