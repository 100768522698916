import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function getAvailableBalance(authToken, healthId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/star-health/cashless-booking/get-available-balance?healthId=${healthId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp.data);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabOrders(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/dashboard-v2?version=180000717`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabTestsCart(authToken, cartId) {
  const headers = {
    authorization: authToken,
  };

  let url = `${config.apiBaseUrl}/labs-v2/cart`;
  if (cartId) {
    url += `/${cartId}`;
  }
  return axios
    .get(url, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabTests(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/all`, {
      headers,
      params: {
        s: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addLabTest(labId, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/add`,
      {
        labId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'changeTestType'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadPrescription(requestData, onUploadProgress, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs/prescriptions/file`, requestData, {
      headers,
      onUploadProgress,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeFileFromPrescription(fileId, prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/prescriptions/file/remove`,
      {
        fileId,
        prescriptionId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
/**
 * 
 * @param {String} cartItemId 
 * @param {String} cartId 
 * @param {String} authToken 
 * @returns 
 */
export function removeLabTest(cartItemId, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/cart/remove`,
      {
        cartItemId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

// export function getLabSlots(authToken) {
//   const headers = {
//     authorization: authToken,
//   };

//   return axios
//     .get(`${config.apiBaseUrl}/labs/slots`, {
//       headers,
//     })
//     .then(resp => {
//       const responseBody = resp.data;
//       if (responseBody.message === 'success') {
//         return responseBody;
//       } else {
//         const err = new Error('Invalid response');
//         err.data = responseBody;
//         showError(err);
//         throw err;
//       }
//     })
//     .catch(err => {
//       showError(err);
//       throw err;
//     });
// }

export function selectLabSlot(slotId, date, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/cart/select-slot`,
      {
        slotId,
        date,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getSavedAddresses(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/address`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getSavedPatients(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/patients`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveNewPatient(patientDetails, authToken) {
  console.log(authToken);
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs/patients`, patientDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editPatient(patientDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/patients/edit`, patientDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removePatient(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/patients/remove`,
      {
        patientId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveNewAddress(addressDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/address`, addressDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editAddress(addressDetails, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/address/edit`, addressDetails, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeAddress(addressId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/address/remove`,
      {
        addressId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
};

export function selectPatient(cartId, patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-patient`,
      {
        patientId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectAddress(addressId, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };
  console.log(addressId, cartId);
  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-address`,
      {
        addressId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectSlot(slotId, cartId, date, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-slot`,
      {
        slotId,
        date,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getOrderSummary(cartId, authToken, noAddress) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/cart/summary/${cartId}?isStarPatient=true&isCenterVisit=${noAddress}`,
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        if (
          responseBody &&
          responseBody.errorMessage ===
            "Cannot read property 'partnerId' of undefined"
        ) {
          return responseBody;
        }
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      console.log(err);
      if (
        err &&
        err.response &&
        err.response.data &&
        (err.response.data.errorMessage ===
          "Cannot read property 'partnerId' of undefined" ||
          err.response.data.errorMessage === 'Address details not selected' ||
          err.response.data.errorMessage === 'Patient details not selected')
      ) {
        return { page: 'lab-select' };
      }
      showError(err);
      throw err;
    });
}

export function getUserPrescriptions(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/prescriptions`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function payLabCart(
  amount,
  cartId,
  starHealthBookingId,
  history,
  authToken,
  isStarUpload
) {
  const headers = {
    authorization: authToken,
  };
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment
    console.log(starHealthBookingId, 'The main Star Health booking Id');
    let starQueryParam = isStarUpload ? `isStarUpload=true` : `isStarPatient=true`;
    window.location.assign(
      `${
        config.apiBaseUrl
      }/labs-v2/transact?amount=${amount}&cartId=${cartId}&auth=${
        authToken.split(' ')[1]
      }&${starQueryParam}&isPbAhc=true&starHealthBookingId=${encodeURIComponent(
        starHealthBookingId
      )}`
    );
  };
  if (amount === 0) {
    return redirectToPaymentGateway();
  }
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/labs-v2/transact`, {
          headers,
          params: {
            amount,
            cartId,
            isStarPatient: true,
            starHealthBookingId,
          },
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'lab',
                transactionId: responseBody.transactionId,
                successPage: `lab-test/track`,
                failurePage: `lab-test/order-summary`,
                failureStateParameters: {
                  paymentNext: true,
                  cartId: cartId,
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Lab Tests',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      console.log(err);
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/order-summary', {
            cartId,
            paymentNext: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/order-summary', {
            cartId,
            paymentNext: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        redirectToPaymentGateway();
      }
    });
}

export function getTranscriptionPreSubmitSummary(
  prescriptionId,
  patientId,
  addressId,
  slotId,
  collectionDate,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/transcription/pre-submit`,
      {
        prescriptionId,
        patientId,
        addressId,
        slotId,
        collectionDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitForTranscription(
  prescriptionId,
  patientId,
  addressId,
  slotId,
  collectionDate,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs/prescriptions/submit-request`,
      {
        prescriptionId,
        patientId,
        addressId,
        slotId,
        collectionDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getTranscriptionStatus(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/transcription/request/${prescriptionId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getOrderStatus(orderId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/order/status/${orderId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function verifyPincode(pincode, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/pincode/check`, {
      headers,
      params: {
        pincode,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPrescription(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/prescriptions/${prescriptionId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getVisitPresciptions(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/online/prescriptions`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchCities(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/cities`, {
      headers,
      params: {
        s: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchLocation(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/location/auto-suggest`, {
      headers,
      params: {
        q: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function trackLabOrder(transactionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs/phonepe/transaction/status/${transactionId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchLabOrderDetail(orderId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/order/${orderId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function getDigitsationStatus(digitisationId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/digitisation/${digitisationId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllLabsOrder(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/orders-v2`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitDigitsationRequest(prescriptionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/digitisation/submit-request`,
      {
        prescriptionId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabs(cartId, lat, long, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/cart/partners/${cartId}?lat=${lat}&long=${long}&isStarHealthPackage=true`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function selectLabPartner(cartId, partnerId, meta, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/select-partner`,
      {
        cartId,
        partnerId,
        meta,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getLabSlots(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/slots?cartId=${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAddress(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/address`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPatients(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/patients?isStarPatient=true`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementSubmitLabs(cartId, patientId, date, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/reimburse/submit`,
      {
        cartId,
        patientId,
        date,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementDetails(reimbursementId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/reimburse/details/${reimbursementId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementInvoiceSubmit(formData, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/reimbursements/invoice`, formData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function bookAhcRequest(patientId, addressId, preferredDate, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/add-request`,
      {
        patientId,
        addressId,
        preferredDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAhcRequestForUser(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/get-request-for-user`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchCopayOrderDetail(orderId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/copay/checkout?orderId=${orderId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function payLabCopay(orderId, history, authToken) {
  const headers = {
    authorization: authToken,
  };
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment
    window.location.assign(
      `${config.apiBaseUrl}/labs-v2/copay/transact?orderId=${orderId}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };

  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/labs-v2/copay/transact`, {
          headers,
          params: {
            orderId,
          },
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'lab',
                transactionId: responseBody.transactionId,
                successPage: `lab-test/track`,
                failurePage: `lab-test/copay`,
                failureStateParameters: {
                  paymentNext: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Lab Tests',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      console.log(err);
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/copay', {
            orderId,
            paymentNext: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('lab-test/copay', {
            orderId,
            paymentNext: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        redirectToPaymentGateway();
      }
    });
}

export function getSuezAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/suez`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getDream11AhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/dream11`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getIndigridAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/indigrid`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getSelAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/sel`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getAhcPartners(authToken, lat, long) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/list-partners`, {
      headers,
      params: {
        lat,
        long,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function bookAhcOrder(
  patientId,
  preferredDate,
  partnerId,
  centerId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/book-order`,
      {
        patientId,
        partnerId,
        centerId,
        collectionDate: preferredDate,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function packageDetails(packageId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/labs-v2/package-details?packageId=${packageId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addPackageToCart(cartId, packageId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/add`,
      {
        cartId,
        packageId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'changeTestType'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAddonToCart(cartId, addOnTestId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/cart/add`,
      {
        addOnTestId,
        cartId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getBottomCartText(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/cart/summary/${cartId}?bottomCart=1`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        if (
          responseBody &&
          responseBody.errorMessage ===
            "Cannot read property 'partnerId' of undefined"
        ) {
          responseBody.page = 'lab-select';
          return responseBody;
        }
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      console.log(err);
      if (
        err &&
        err.response &&
        err.response.data &&
        (err.response.data.errorMessage ===
          "Cannot read property 'partnerId' of undefined" ||
          err.response.data.errorMessage === 'Address details not selected' ||
          err.response.data.errorMessage === 'Patient details not selected')
      ) {
        return { page: 'lab-select' };
      }
      showError(err);
      throw err;
    });
}

export function fetchSamplePrescription(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/upload-prescription/details`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteCart(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/remove-cart/${cartId}`, null, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadAddressProof(
  requestData,
  onUploadProgress,
  cartId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/upload/address-proof/${cartId}`,
      requestData,
      {
        headers,
        onUploadProgress,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteAddressProof(url, cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/delete/address-proof/${cartId}`,
      {
        url,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAllAddressProofFile(cartId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/address-proof/${cartId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCovidDashboard(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/labs-v2/covid-dashboard`, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function covidPackageDetails(id, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/labs-v2/covid-test/${id}`, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCovidHomeCareDashboard(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/subscription/covid-home-care-dashboard`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markSamplePickedUp(orderId, samplePickup, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/order/sample-pickup`,
      {
        orderId,
        samplePickup,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function covidDeclaration(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/covid-test-result`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitAhcReimbursementRequest(
  requestData,
  onUploadProgress,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/ahc/create-reimbursement-request`,
      requestData,
      {
        headers,
        onUploadProgress,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAhcParentalRelatives() {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/parental/patients`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAhcParentRelative(name, dob, gender, phone, relationId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    name,
    dob,
    gender,
    phone,
    relationId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/ahc/parental/patients`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addInfoedgeAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/infoedge/addParentalAHCToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addDream11AhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/dream11/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addGartnerAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/gartner/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addIndigridAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/indigrid/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addSelAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(`${config.apiBaseUrl}/labs-v2/sel/addAHCTestToCart`, requestData, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addCanaraHSBCAhcToCart(cartId, testId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    testId,
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/canaraHSBC/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCanaraHSBCAhcPackage(patientId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/canarahsbc?patientId=${patientId}`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function addStarHealthAhcToCart(cartId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  const requestData = {
    cartId,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/labs-v2/star-health/addAHCTestToCart`,
      requestData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getStarHealthAhcPackage(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs-v2/ahc/star-health`, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}


export function updateUserEmailInUserProfile(email, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    email
  }

  return axios
    .post(`${config.apiBaseUrl}/users/update/profile`, body, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'profileUpdated') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

