import { store } from '../store/index';

export const showError = (err) => {
  let errorTitle = 'Something went wrong';
  let errorDesc =
    'We were unable to fulfill your request. Please try again later.';

  if (err.response && err.response.data && err.response.data.err) {
    errorDesc = err.response.data.err;
  } else if (err && err.data && err.data.err) {
    errorDesc = err.data.err;
  }

  if (err.response && err.response.data && err.response.data.errorMessage) {
    errorDesc = err.response.data.errorMessage;
  } else if (err && err.data && err.data.errorMessage) {
    errorDesc = err.data.errorMessage;
  }

  if (err.response && err.response.data && err.response.data.errorHeader) {
    errorTitle = err.response.data.errorHeader;
  } else if (err && err.data && err.data.errorHeader) {
    errorTitle = err.data.errorHeader;
  }

  console.log({ err, errorTitle, errorDesc });

  store.dispatch({
    type: 'SHOW_ERROR',
    payload: {
      errorTitle: errorTitle,
      errorDesc: errorDesc,
    },
  });
};
