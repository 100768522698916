import React, { Component } from 'react';
import {
  checkIdAndGetMagicLink,
  checkIdandPhoneToGetMagicLink,
} from '../../services/star-health';
import moment from 'moment';
import Loader from '../../images/common/star2.gif';
import { loginMagicLink, updateLoginState } from '../../actions';
import { connect } from 'react-redux';
class StarHealth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      id: null,
      token: null,
      moduleName: null,
    };
    const { location } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const id = params.get('id');
      const phone = params.get('phone');
      const moduleName = params.get('moduleName');
      console.log(id, phone);
      if (token && id && phone) {
        this.state.phone = phone;
        this.state.token = token;
        this.state.id = id;
        if (moduleName) {
          this.state.moduleName = moduleName;
        }
      } else if (token && id) {
        this.state.token = token;
        this.state.id = id;
      }
    }
  }

  componentDidMount() {
    if (this.state.phone) {
      const body = {
        token: this.state.token,
        sId: this.state.id,
        phone: this.state.phone,
      };
      this.checkIdandPhoneToGetLink(body);
      this.setState({ loading: true });
    } else {
      const body = {
        token: this.state.token,
        sId: this.state.id,
      };
      this.checkIdAndGetLink(body);
    }
  }

  checkIdandPhoneToGetLink(body) {
    const { history, dispatch } = this.props;
    checkIdandPhoneToGetMagicLink(body)
      .then((res) => {
        this.setState({ loading: false });
        console.log(res.result);
        if (res.magicCode) {
          dispatch(updateLoginState('LOGGED_OUT'));
          dispatch({
            type: 'UNSET_USER_INFO',
          });
          dispatch({
            type: 'SET_NEW_APP_LOGIN',
            payload: true,
          });
          dispatch(loginMagicLink(res.magicCode, history, this.state.moduleName));
        } else {
          window.location.assign(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkIdAndGetLink(body) {
    const { history, dispatch } = this.props;
    checkIdAndGetMagicLink(body)
      .then((res) => {
        this.setState({ loading: false });
        console.log(res.result);
        if (res.magicCode) {
          dispatch(updateLoginState('LOGGED_OUT'));
          dispatch({
            type: 'UNSET_USER_INFO',
          });
          dispatch(loginMagicLink(res.magicCode, history));
        } else {
          window.location.assign(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <>
        <div className="w-full flex center-align justify-center">
          {this.state.loading && (
            <img src={Loader} alt="...loading" width="70px" height="70px" />
          )}
        </div>
      </>
    );
  }
}

export default connect()(StarHealth);
