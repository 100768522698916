export const tabFormatter = (tab) => {
  switch (tab) {
    case 'pharmacy':
      return '/pharmacy';
    case 'hra':
      return '/hra';
    case 'phc':
      return '/lab-test/select-policy';
    case 'book-doc':
      return '/consultation/select-policy';
    case 'book-lab':
      return '/lab-upload';
    case 'dependents':
      return '/manage-dependents';
    default:
      return '/home';
  }
};
