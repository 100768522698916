import React from 'react';
import PropTypes from 'prop-types';

import arrowRight from '../../images/common/arrow-right.svg';
import ellipseIcon from '../../images/common/ellipse.svg';
import tickIcon from '../../images/common/tick.svg';
import cautionIcon from '../../images/common/caution.svg';
import greenTick from '../../images/common/green-tick.svg';
import profileIcon from '../../images/common/profile.svg';
import stethoscope from '../../images/pharmacy/stethoscope.png';
import arrowRightFill from '../../images/lab-test/arrowright-fill.svg';

const PrescriptionStatusCard = ({ orderRequest, onTap }) => {
  const { cardType: type, digitisationId } = orderRequest;

  if (type === 'pending-digitisation') {
    return (
      <div
        className="hover p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid rounded-12"
        onClick={onTap}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <span className="text-title-s font-semibold">
            Prescription Uploaded
          </span>
          <img className="float-right" src={arrowRight} alt="right" />
        </div>
        <div className="flex flex-row items-center pb-4px">
          <img
            src={ellipseIcon}
            alt="ellipse"
            height={11}
            width={12}
            className="icon-spin"
          />
          <span className="text-body-s text-secondary-shades6 font-regular pl-8px ">
            Transcribing Prescription
          </span>
        </div>
        <div className="text-body-s font-regular text-txtsecondary-shades8 pb-24px">
          The transcription will take 12hr-24hrs
        </div>
        {/* <div className="text-chip text-txtsecondary">
          {orderRequest.prescribedTime}
        </div> */}
      </div>
    );
  } 
  else if (type === 'reimbursement') {
    return (
      <div
        className="hover p-16px pb-12px mt-16px border border-txtsecondary-shades7 border-solid rounded-12"
        onClick={onTap}
      >
        <div className="flex flex-row justify-between	items-center pb-4px">
          <span className="text-title-s font-semibold">Reimbursement</span>
          <img className="float-right" src={arrowRight} alt="right" />
        </div>
        <div>
          <p className="lab-test-name mt-13">{orderRequest.patientName}</p>
          <p className="txt-grey-600-12 mt-4 mb-4">
            {orderRequest.prescriptionGivenOn}
          </p>

          <p className="txt-black-600-12 lab-home-btn hover" onClick={onTap}>
            {orderRequest.btnLabel}
          </p>
        </div>
      </div>
    );
  } else if (type === 'booked-order') {
    return (
      <div
        className="p-16px pt-0 pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12"
        onClick={() => {
          onTap(orderRequest);
        }}
      >
        <span
          className="py-4px px-12px text-secondary-shades2 text-body-s font-semibold"
          style={{
            background: 'rgba(211,197,251, 0.2)',
            borderRadius: '0px 0px 8px 8px',
          }}
        >
          {orderRequest.testsType}
        </span>
        <div className="flex flex-row justify-between pt-12px items-center pb-4px">
          <div className="flex flex-row items-center">
            {orderRequest.status === 'pending' ||
            orderRequest.status === 'completed' ? (
              <img src={tickIcon} alt="tick" height={11} width={12} />
            ) : (
              <img src={cautionIcon} alt="tick" height={11} width={12} />
            )}
            <div
              className="text-body-s font-semibold pl-8px"
              style={
                orderRequest.status === 'pending' ||
                orderRequest.status === 'completed'
                  ? { color: '#3F8CFF' }
                  : { color: '#FF754C' }
              }
            >
              {orderRequest.status === 'payment-pending'
                ? orderRequest.pendingCopayText || orderRequest.status
                : orderRequest.status}
            </div>
          </div>
        </div>
        <div className="text-title-s font-semibold">
          {orderRequest.testNames}
        </div>
        {orderRequest.doctorName && (
          <div className="flex flex-row items-center pb-2px">
            <img src={stethoscope} alt="stethoscope" height={12} width={12} />
            <div className="text-body-s text-txtsecondary font-medium pl-4px ">
              {orderRequest.doctorName}
            </div>
          </div>
        )}
        <div className="flex flex-row items-center pb-24px">
          <img src={profileIcon} alt="profile" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.patientName}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="text-chip text-txtsecondary">
            {orderRequest.bookedOn}
          </div>
          <div className="flex flex-row hover">
            <p className="text-body-l text-primary font-semibold mr-8px">
              {orderRequest.status === 'payment-pending'
                ? 'Pay & Confirm'
                : 'View Details'}
            </p>
            <img src={arrowRightFill} />
          </div>
        </div>
      </div>
    );
  } else if (type === 'available-cart') {
    return (
      <div className="p-16px pt-0 pb-12px mt-16px border border-txtsecondary-shades7 border-solid	rounded-12">
        <div className="flex flex-row items-center mt-16px">
          <img src={tickIcon} alt="tick" height={11} width={12} />
          <p className="text-body-s font-semibold text-secondary-shades6 ml-4px">
            Transcribed
          </p>
        </div>
        <div className="text-body-l font-semibold text-txtlight pt-4px pb-4px">
          Tests Ready for order
        </div>
        <div className="text-title-s font-semibold">
          {orderRequest.testNames}
        </div>
        {orderRequest.doctorName && (
          <div className="flex flex-row items-center pb-2px">
            <img src={stethoscope} alt="stethoscope" height={12} width={12} />
            <div className="text-body-s text-txtsecondary font-medium pl-4px ">
              {orderRequest.doctorName}
            </div>
          </div>
        )}
        <div className="flex flex-row items-center pb-24px">
          <img src={profileIcon} alt="profile" height={12} width={12} />
          <div className="text-body-s text-txtsecondary font-medium pl-4px ">
            {orderRequest.patientName}
          </div>
        </div>
        <div>
          <div className="text-chip text-txtsecondary">
            {orderRequest.prescriptionGivenOn}
          </div>
          <div
            className="bg-primary hover rounded-12 max-w-100 mt-8px py-10px text-center"
            onClick={() => {
              onTap(orderRequest);
            }}
          >
            <p className="text-body-l text-textbox font-semibold">
              View & Confirm
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

PrescriptionStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default PrescriptionStatusCard;
